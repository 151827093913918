import React, { useEffect, useState, useRef } from 'react';
import './OrderCheckout.css';
import { loadStripe } from "@stripe/stripe-js";
import { Elements } from "@stripe/react-stripe-js";
import axios from 'axios';

import PaymentMethodForm from '../../PaymentMethodForm/PaymentMethodForm';
import { useAuth } from '../../../hooks';

const stripePromise = loadStripe("pk_live_51OVpZKE0gFIeJavz3pxAWLWNTG2MDsUk7hOU1MgabyQyrMtUalczyUdcbuTmeD3EI6optVW0kEzOeYF7NQEglA3l00YosU8cKh");
export default function OrderCheckout(props) {
  const auth = useAuth();

  const [fare, setFare] = useState(119);

  const [paymentMethods, setPaymentMethods] = useState(null);
  const [paymentMethodAdded, setPaymentMethodAdded] = useState(false);

  const [isLoading, setIsLoading] = useState(false);
  const [clientSecret, setClientSecret] = useState(null);

  const buttonRef = useRef(null);
  const [buttonGradientStyle, setButtonGradientStyle] = useState({
    background: 'linear-gradient(90deg, rgba(110,190,106,1), rgba(96,156,94,1))'
  });

  useEffect(() => {
    axios.get('/requester/payments/methods', {
      headers: {
        'Authorization': auth.user['auth_token']
      },
      params: {
        "customer_uuid": auth.user['requester']['account_id']
      }
    }).then((response) => {
      setPaymentMethods(response.data);
    }).catch((error) => {
      console.log(error);
    });
  }, []);

  // Fetch the client secret from the backend when the component mounts
  useEffect(() => {
    if (paymentMethods !== null && paymentMethods.length === 0) {
      axios.post('/requester/payments/method', {
        'customer_uuid': auth.user['requester']['account_id']
      }, {
        headers: {
          'Authorization': auth.user['auth_token']
        }
      }).then((response) => {
        setClientSecret(response.data['setupIntent']);
      }).catch((error) => {
        console.log(error);
      });
    }
  }, [paymentMethods]);

  const handleMouseMove = (event) => {
    const { left, top, width, height } = buttonRef.current.getBoundingClientRect();
    const x = event.clientX - left;
    const y = event.clientY - top;

    const xPercent = (x / width) * 100;
    const yPercent = (y / height) * 100;

    setButtonGradientStyle({
      background: `radial-gradient(circle at ${xPercent}% ${yPercent}%, rgba(110,190,106,1), rgba(96,156,94,1))`
    });
  };

  const formatQuantity = () => {
    if (props.order === null) {
      return;
    }
    if (props.order['requested_quantity'] === 1) {
      return "1 user";
    }
    return props.order['requested_quantity'] + " users";
  };
  
  const formatPersona = () => {
    var personaString = "";
    if (props.order === null) {
      return;
    }
    let orderTraits = props.order['requested_traits'];
    if ("Occupation" in orderTraits && orderTraits["Occupation"].length > 0) {
      personaString += orderTraits["Occupation"][0] + ", ";
  }
  if ("Industry" in orderTraits && orderTraits["Industry"].length > 0) {
      personaString += orderTraits["Industry"][0] + ", ";
  }
    if (personaString === "") {
      return "Anyone";
    } else {
      return personaString.substring(0, personaString.length - 2);
    }
  };

  const formatFare = () => {
    if (fare !== null) {
      // Convert the number to a string and add decimal points
      return "$" + (fare / 100).toFixed(2);
    }
  };

  const formatTotal = () => {
    if (props.order === null) {
      return;
    }
    let total = fare * props.order['requested_quantity'];
    // Convert the number to a string and add decimal points
    return "$" + (total / 100).toFixed(2);
  };

  const togglePaymentMethodAdded = () => {
    setPaymentMethodAdded(true);
  }

  const handlePlaceOrder = () => {
    setIsLoading(true);
    props.next(fare);
  };
  
  return (
    <div className="gray-modal-background" onClick={(e) => props.exit(e)}>
      <div className="order-checkout-base">
        <div className="ordering-pill">
          <div className="ordering-pill-sections">
            <div className="ordering-pill-section quantity" onClick={() => props.editOrder(0)}>
              <h5 className="black-header-6-text">Quantity</h5>
              <div className="ordering-pill-quantity">
                <div className="ordering-pill-quantity-text-container">
                  <p className="black-regular-5-text">{formatQuantity()}</p>
                </div>
              </div>
            </div>
            <div className="ordering-pill-section persona" onClick={() => props.editOrder(1)}>
              <h5 className="black-header-6-text">Persona</h5>
              <p className="black-regular-5-text">{formatPersona()}</p>
            </div>
            <div className="ordering-pill-section message" onClick={() => props.editOrder(2)}>
              <h5 className="black-header-6-text">Message</h5>
              <div className="ordering-pill-message">
                <p className="ordering-pill-message-text">{props.order !== null && props.order['request_payload']}</p>
              </div>
            </div>
          </div>
        </div>
        <div className="order-checkout-order-summary">
          {
            fare === null ? (
              <div className="loading-circle" />
            ) : (
              <>
                <h4 className="black-header-4-text">Order summary</h4>
                <div className="order-checkout-pricing">
                  <div className="order-checkout-pricing-item">
                    <h5 className="light-gray-header-6-text">Cost per message</h5>
                    <p className="black-regular-5-text">{formatFare()}</p>
                  </div>
                  <div className="order-checkout-pricing-item">
                    <h5 className="light-gray-header-6-text">Quantity</h5>
                    <p className="black-regular-5-text">{props.order !== null && props.order['requested_quantity']}</p>
                  </div>
                  <div className="order-checkout-pricing-item">
                    <h5 className="black-header-6-text">Total</h5>
                    <p className="bordering-pill-quantity-text-containerlack-header-5-text">{formatTotal()}</p>
                  </div>
                </div>
                {
                  paymentMethods === null ? (
                    <div className="full-width-button disabled">
                      <div className="loading-circle" />
                    </div>
                  ) : [
                    paymentMethods.length === 0 && !paymentMethodAdded && (
                      clientSecret === null ? (
                        <div className="full-width-button disabled">
                          <div className="loading-circle" />
                        </div>
                      ) : (
                        <Elements stripe={stripePromise} options={{clientSecret}}>
                          <PaymentMethodForm clientSecret={clientSecret} onSuccess={togglePaymentMethodAdded} />
                        </Elements>
                      )
                    )
                  ]
                }
                {
                  paymentMethods === null || (paymentMethods.length === 0 && !paymentMethodAdded) ? (
                    <div className="gray-full-width-button">Place order</div>
                  ) : [
                    isLoading ? (
                      <div className="full-width-button disabled">
                        <div className="loading-circle" />
                      </div>
                    ) : (
                      <div className="full-width-button" key={buttonGradientStyle} ref={buttonRef} onMouseMove={handleMouseMove} style={buttonGradientStyle} onClick={handlePlaceOrder}>Place order</div>
                    )
                  ]
                }
              </>
            )
          }
        </div>
      </div>
    </div>
  );
}