import React, { useState } from 'react';

import { useAuth } from '../hooks';

import { RequesterAuthenticated, RequesterUnauthenticated } from '.';
import { AuthController } from '../components/auth';
import { OrderingController } from '../components/ordering';

export default function RequesterLandingPage(props) {
  const auth = useAuth();

  const [authState, setAuthState] = useState("UNSET");
  const [orderingState, setOrderingState] = useState("UNSET");
  const [order, setOrder] = useState(null);
  const [selectedSection, setSelectedSection] = useState(0);

  const activateAuth = (authState) => {
    setAuthState(authState);
    if (authState === "SIGN_UP_THROUGH_ORDER") {
      setOrderingState("UNSET");
    } else {
      deactivateOrdering();
    }
    document.body.style.overflow = 'hidden';
  };
  
  const deactivateAuth = () => {
    document.body.style.overflow = 'auto';
    setAuthState("UNSET");
  };

  const activateOrdering = (initialOrder, initialSelectedSection) => {
    setOrder(initialOrder);
    setSelectedSection(initialSelectedSection);
    setOrderingState("ORDERING");
    deactivateAuth();
    document.body.style.overflow = 'hidden';
  };

  const activateOrderCheckout = () => {
    document.body.style.overflow = 'hidden';
    setOrderingState("ORDER_CHECKOUT");
  };

  const deactivateOrdering = () => {
    document.body.style.overflow = 'auto';
    setOrderingState("UNSET");
    setOrder(null);
  };

  return (
    <>
      <AuthController authState={authState} deactivate={deactivateAuth} accountType={"REQUESTER"} activateOrderCheckout={activateOrderCheckout} />
      <OrderingController order={order} selectedSection={selectedSection} orderingState={orderingState} deactivate={deactivateOrdering} activateAuth={() => activateAuth("SIGN_UP_THROUGH_ORDER")} />
      {
        auth.user ? (
          <RequesterAuthenticated orderingState={orderingState} activateOrdering={activateOrdering} deactivateOrdering={deactivateOrdering} />
        ) : (
          <RequesterUnauthenticated activateAuth={activateAuth} deactivateAuth={deactivateAuth} activateOrdering={activateOrdering} deactivateOrdering={deactivateOrdering} />
        )
      }
    </>
  );
}
