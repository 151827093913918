import React, { useState, useContext, createContext } from 'react';
import { confirmSignIn, fetchAuthSession, resendSignUpCode, signIn, signOut, signUp } from '@aws-amplify/auth';
import axios from 'axios';
import { v4 as uuidv4 } from 'uuid';

const AuthContext = createContext();

export function ProvideAuth({ children }) {
	const auth = useProvideAuth();
	return <AuthContext.Provider value={auth}>{children}</AuthContext.Provider>;
}

export const useAuth = () => {
	return useContext(AuthContext);
}

export function useProvideAuth() {
	const [user, setUser] = useState(null);

	const getUser = () => {
		return new Promise((resolve, reject) => {
			fetchAuthSession().then(response => {
				var userUUID = "";
				var idToken = "";

				if ('userSub' in response && response['userSub'] !== undefined) {
					userUUID = response['userSub'];
				} else {
					setUser(false);
					return;
				}
				if ('tokens' in response && 'idToken' in response['tokens']) {
					idToken = response['tokens']['idToken'].toString();
				} else {
					setUser(false);
					return;
				}

				axios.get('/user', {
					headers: {
						'Authorization': idToken
					},
					params: {
						"user_uuid": userUUID
					}
				}).then((response) => {
					var userResponse = response['data'];
					userResponse['auth_token'] = idToken;
					setUser(userResponse);
					resolve(userResponse);
				}).catch((error) => {
					console.log(error);
				});
			}).catch((error) => {
				setUser(false);
				console.log(error);
			});
		});
	}

	const handleSignIn = (username) => {
		return new Promise((resolve, reject) => {
			signIn({
				username,
				options: {
					authFlowType: 'CUSTOM_WITHOUT_SRP'
				}
			}).then(response => {
				resolve();
			}).catch(error => {
				console.log(error);
				switch (error.code) {
					case "UserNotFoundException":
						reject("Email not found. Please create an account first.");
						break;
					case "NotAuthorizedException":
						reject("Incorrect username.");
						break;
					default:
						reject(error);
				}
			});
		});
	};

	const handleSignUp = (firstName, lastName, email, account_type) => {
		return new Promise((resolve, reject) => {
			signUp({
				username: uuidv4(),
				password: getRandomString(30),
				options: {
					userAttributes: {
						name: firstName + " " + lastName,
						email: email,
						'custom:account_type': account_type
					}
				}
			}).then(() => {
				resolve();
			}).catch((error) => {
				console.log(error);
				switch(error.code) {
					case "UsernameExistsException":
						reject("This username is taken. Please try another.");
						break;
					default:
						reject(error);
				}
			});
		});
	};

	const handleConfirmSignIn = (challengeResponse) => {
		return new Promise((resolve, reject) => {
			confirmSignIn({ challengeResponse })
				.then((response) => {
					if (response['isSignedIn']) {
						getUser().then(() => {
							resolve();
						})
					} else {
						reject("Your code was invalid. Please try again.");
					}
				})
				.catch((error) => {
					console.log(error);
					switch (error.code) {
						case "NotAuthorizedException":
							reject("This code has expired. Please try again.");
							break;
						default:
							reject(error);
					}
				});
		});
	};

	const handleResendCode = (username) => {
		return new Promise((resolve, reject) => {
			resendSignUpCode(username).then(() => {
				resolve();
			}).catch((error) => {
				reject(error);
			});
		});
	};

	const handleSignOut = () => {
		return new Promise((resolve, reject) => {
			signOut().then(() => {
				setUser(null);
				resolve();
			}).catch((error) => {
				reject(error);
			});
		});
	};

	return {
		user,
		handleSignIn,
		handleSignUp,
		handleSignOut,
		handleResendCode,
		handleConfirmSignIn,
		getUser
	};
}

function getRandomString(bytes) {
	const randomValues = new Uint8Array(bytes);
	window.crypto.getRandomValues(randomValues);
	return Array.from(randomValues).map(intToHex).join('');
}

function intToHex(nr) {
	return nr.toString(16).padStart(2, '0');
}