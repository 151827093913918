import React, { useEffect, useState } from 'react';
import axios from 'axios';

import { ResponderActionList, ResponderThreadViewer } from '.';

import { useAuth } from '../../hooks';

export default function ResponderBase(props) {
    const auth = useAuth();

    const [actions, setActions] = useState([]);
    const [selectedIndex, setSelectedIndex] = useState(null);
    const [thread, setThread] = useState(null);

    useEffect(() => {
        if (auth.user) {
            let payload = {
                "responder_uuid": auth.user['user_uuid']
            };
            axios.get('/responder/actions', {
                headers: {
                    'Authorization': auth.user['auth_token']
                },
                params: payload
            }).then((response) => {
                setActions(response.data);
            }).catch((error) => {
                console.log(error);
            });
        }
    }, [auth.user]);

    const handleSelection = (index) => {
        if (selectedIndex !== index) {
            setSelectedIndex(index);
            getThread(actions[index]["thread_uuid"])

            let newActions = [...actions];
            newActions[index]["status"] = "READ";
            setActions(newActions);
        }
    };

    const handleSend = (message) => {
        axios.post('/responder/message', JSON.stringify(message), {
            headers: {
                'Authorization': auth.user['auth_token']
            }
        }).catch((error) => {
            console.log(error);
        });
        let newThread = {...thread};
        let newMessages = thread["actions"][selectedIndex]["messages"];
        newMessages.push(message);
        newThread["actions"][selectedIndex]["messages"] = newMessages;
        setThread(newThread);
    };

    const getThread = (thread_uuid) => {
        let payload = {
            "thread_uuid": thread_uuid,
        };
        axios.get('/thread', {
            headers: {
                'Authorization': auth.user['auth_token']
            },
            params: payload
        }).then((response) => {
            setThread(response.data);
        }).catch((error) => {
            console.log(error);
        });
    };

    return (
        <div className="requester-threads-base">
            <ResponderActionList 
                actions={actions}
                selectedIndex={selectedIndex}
                onClick={handleSelection} />
            {
                selectedIndex !== null ? (
                    <ResponderThreadViewer 
                        thread={thread}
                        action={actions[selectedIndex]}
                        selectedIndex={selectedIndex}
                        onSend={handleSend} />
                ) : (
                    <div className="requester-thread-viewer">
                        <div className="requester-threads-viewer-unselected">
                            <p className="black-regular-5-text">Select a message</p>
                        </div>
                    </div>
                )
            }
        </div>
    );
}