import React, { useEffect, useState } from 'react';
import axios from 'axios';

import { RequesterThreadList, RequesterThreadViewer } from '.';

import { useAuth } from '../../hooks';

export default function RequesterBase(props) {
    const auth = useAuth();

    const [threads, setThreads] = useState([]);
    const [selectedGroup, setSelectedGroup] = useState(null);
    const [selectedThread, setSelectedThread] = useState(null);

    const [actions, setActions] = useState([]);
    const [availableModes, setAvailableModes] = useState(["User Research"]);

    useEffect(() => {
        let payload = {
            "requester_uuid": auth.user['user_uuid']
        };
        axios.get('/requester/threads', {
            headers: {
                'Authorization': auth.user['auth_token']
            },
            params: payload
        }).then((response) => {
            setThreads(response.data);
        }).catch((error) => {
            console.log(error);
        });
    }, [props.orderingState]);

    const handleThreadSelection = (groupIndex, threadIndex, threadUUID) => {
        if (selectedGroup !== groupIndex || selectedThread !== threadIndex) {
            setSelectedGroup(groupIndex);
            setSelectedThread(threadIndex);
            getThread(threadUUID);

            let newThreads = [...threads];
            if (newThreads[groupIndex]["threads"][threadIndex]["status"] !== "NEW") {
                newThreads[groupIndex]["threads"][threadIndex]["status"] = "READ";
                setThreads(newThreads);
            }
        } else if (selectedGroup === groupIndex && selectedThread === threadIndex) {
            setSelectedGroup(null);
            setSelectedThread(null);
        }
    };

    const handleSend = (action) => {
        axios.post('/requester/action', JSON.stringify(action), {
            headers: {
              'Authorization': auth.user['auth_token']
            }
        }).catch((error) => {
            console.log(error);
        });
        let newActions = [...actions];
        newActions.push(action);
        setActions(newActions);

        let newThreads = [...threads];
        newThreads[selectedGroup]["threads"][selectedThread]["display_text"] = action["payload"];
        newThreads[selectedGroup]["threads"][selectedThread]["updated_at"] = Date.now();
        if (newThreads[selectedGroup]["threads"][selectedThread]["requester"]["requester_state"] === "NEW") {
            newThreads[selectedGroup]["threads"][selectedThread]["requester"]["requester_state"] = "READ";
        }
        setThreads(newThreads);
    };

    const getThread = (thread_uuid) => {
        let payload = {
            "thread_uuid": thread_uuid,
        };
        axios.get('/thread', {
            headers: {
                'Authorization': auth.user['auth_token']
            },
            params: payload
        }).then((response) => {
            setActions(response.data["actions"]);
        }).catch((error) => {
            console.log(error);
        });
    };

    return (
        <div className="requester-threads-base">
            <RequesterThreadList 
                threads={threads}
                selectedGroupIndex={selectedGroup}
                selectedThreadIndex={selectedThread}
                onClick={handleThreadSelection} />
            {
                selectedGroup !== null && selectedThread !== null ? (
                    <RequesterThreadViewer 
                        thread={threads[selectedGroup]["threads"][selectedThread]}
                        orderTraits={threads[selectedGroup]["requested_traits"]}
                        actions={actions}
                        availableModes={availableModes}
                        selectedGroup={selectedGroup}
                        selectedThread={selectedThread}
                        onSend={handleSend} />
                ) : (
                    <div className="requester-thread-viewer">
                        <div className="requester-threads-viewer-unselected">
                            {
                                threads.length > 0 ? (
                                    <p className="black-regular-5-text">Select a thread</p>
                                ) : (
                                    <p className="green-regular-5-text">Start your first thread</p>
                                )
                            }
                        </div>
                    </div>
                )
            }
        </div>
    );
}